// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// https://coolors.co/41d3bd-fffff2-791e94-de6449-407899

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #06d6a0;
  --ion-color-primary-rgb: 6, 214, 160;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #00ba88;
  --ion-color-primary-tint: #24e5b1;

  /** secondary **/
  --ion-color-secondary: #ffd166;
  --ion-color-secondary-rgb: 255, 209, 102;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #ffcc54;
  --ion-color-secondary-tint: #ffd77b;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #06d656;
  --ion-color-success-rgb: 6, 214, 86;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00b746;
  --ion-color-success-tint: #15eb67;

  /** warning **/
  --ion-color-warning: #ff9d09;
  --ion-color-warning-rgb: 255, 157, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e88c02;
  --ion-color-warning-tint: #ffa620;

  /** danger **/
  --ion-color-danger: #ea264a;
  --ion-color-danger-rgb: 234, 38, 74;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ea264a;
  --ion-color-danger-tint: #ea264a;

  /** dark **/
  --ion-color-dark: #073b4c;
  --ion-color-dark-rgb: 7, 59, 76;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #033444;
  --ion-color-dark-tint: #0c4152;

  /** medium **/
  --ion-color-medium: #777777;
  --ion-color-medium-rgb: 119, 119, 119;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5b5b5b;
  --ion-color-medium-tint: #a5a5a5;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
