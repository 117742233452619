/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.loading-logo {
  img {
    width: 256px;
  }
  text-align: center;
  font-weight: 500;
  font-size: 2.5rem;
}

.dot-bricks-container {
  transform: scale(2);
  transform-origin: center;
}

/**
 * ==============================================
 * Dot Bricks
 * ==============================================
 */
.dot-bricks {
  position: relative;
  top: 10px;
  left: -9999px;
  margin-left: calc(50vw - 5px);
  margin-top: 64px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary);
  box-shadow: 9991px -16px 0 0 var(--ion-color-primary), 9991px 0 0 0 var(--ion-color-primary),
    10007px 0 0 0 var(--ion-color-primary);
  animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
  0% {
    box-shadow: 9991px -16px 0 0 var(--ion-color-primary), 9991px 0 0 0 var(--ion-color-primary),
      10007px 0 0 0 var(--ion-color-primary);
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 var(--ion-color-primary), 9991px 0 0 0 var(--ion-color-primary),
      10007px 0 0 0 var(--ion-color-primary);
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 var(--ion-color-primary), 9991px -16px 0 0 var(--ion-color-primary),
      10007px 0 0 0 var(--ion-color-primary);
  }
  25% {
    box-shadow: 10007px -16px 0 0 var(--ion-color-primary), 9991px -16px 0 0 var(--ion-color-primary),
      9991px 0 0 0 var(--ion-color-primary);
  }
  33.333% {
    box-shadow: 10007px 0 0 0 var(--ion-color-primary), 9991px -16px 0 0 var(--ion-color-primary),
      9991px 0 0 0 var(--ion-color-primary);
  }
  41.667% {
    box-shadow: 10007px 0 0 0 var(--ion-color-primary), 10007px -16px 0 0 var(--ion-color-primary),
      9991px 0 0 0 var(--ion-color-primary);
  }
  50% {
    box-shadow: 10007px 0 0 0 var(--ion-color-primary), 10007px -16px 0 0 var(--ion-color-primary),
      9991px -16px 0 0 var(--ion-color-primary);
  }
  58.333% {
    box-shadow: 9991px 0 0 0 var(--ion-color-primary), 10007px -16px 0 0 var(--ion-color-primary),
      9991px -16px 0 0 var(--ion-color-primary);
  }
  66.666% {
    box-shadow: 9991px 0 0 0 var(--ion-color-primary), 10007px 0 0 0 var(--ion-color-primary),
      9991px -16px 0 0 var(--ion-color-primary);
  }
  75% {
    box-shadow: 9991px 0 0 0 var(--ion-color-primary), 10007px 0 0 0 var(--ion-color-primary),
      10007px -16px 0 0 var(--ion-color-primary);
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 var(--ion-color-primary), 10007px 0 0 0 var(--ion-color-primary),
      10007px -16px 0 0 var(--ion-color-primary);
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 var(--ion-color-primary), 9991px 0 0 0 var(--ion-color-primary),
      10007px -16px 0 0 var(--ion-color-primary);
  }
  100% {
    box-shadow: 9991px -16px 0 0 var(--ion-color-primary), 9991px 0 0 0 var(--ion-color-primary),
      10007px 0 0 0 var(--ion-color-primary);
  }
}

.center-block {
  margin: auto;
  display: block;
}

html {
  background: var(--ion-color-dark-rgb);
}

// don't allow drag images
img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.date-modal {
  .ion-page {
    justify-content: unset;
    padding-top: calc(var(--ion-safe-area-top) + 32px);
  }
}

ion-item {
  ion-label {
    margin-right: 8px;
    white-space: nowrap;
  }
}
